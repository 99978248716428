import {useEffect, useState} from 'react';

import {GetRecoItemsParams, RecoSchemeName} from '../../monetate/monetate.types';
import {MonetateService} from '../../../client/monetate/monetate.service';
import {OrderableItem} from '../../items/item.class';

const useRecommendations = (getRecoItemsParams: GetRecoItemsParams, monetateService: MonetateService) => {
    const [error, setError] = useState(null);
    const [items, setItems] = useState<OrderableItem[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [schemeExplanation, setSchemeExplanation] = useState(``);
    const [schemeName, setSchemeName] = useState<RecoSchemeName>(``);

    useEffect(() => {
        if (!getRecoItemsParams) {
            return;
        }
        monetateService
            .getRecoItems(getRecoItemsParams)
            .then((getRecoItemsRes) => {
                if (getRecoItemsRes && getRecoItemsRes.schemes) {
                    getRecoItemsRes.schemes.forEach((scheme) => {
                        setItems((i) => [...i, ...scheme.items]);
                        setSchemeExplanation(scheme.explanation);
                        setSchemeName(scheme.scheme);
                    });
                    setLoaded(true);
                } else {
                    setLoaded(true);
                }
            })
            .catch(() => {
                setError(`Failed to load recommendations`);
            });
    }, []);

    return {
        items,
        loaded,
        error,
        schemeExplanation,
        schemeName,
    };
};

export default useRecommendations;
