import * as React from 'react';

import {HomePageWidgetStyles} from './HomePageWidget.styles';
import {TemplateHelpers} from '../tools/template-helpers.class';
import {UserList} from '../lists/lists/lists.class';

export interface ListsWidgetProps {
    getListsErr: string;
    widgetLists: UserList[];
}
export const ListsWidget = ({getListsErr, widgetLists}: ListsWidgetProps) => {
    return (
        <HomePageWidgetStyles>
            <div
                className="card tw-h-full !tw-hidden md:!tw-block tw-mb-0"
                id="lists-widget-container"
            >
                <div className="card-header !tw-bg-transparent tw-flex tw-justify-between tw-items-center !tw-px-2">
                    <h4 className="!tw-m-0">Lists</h4>
                    <div>
                        <a href="/lists">View All</a>
                    </div>
                </div>
                <div className="card-list content-scroll tw-block">
                    {getListsErr && <div className="abs-center tw-text-red-500">{getListsErr}</div>}
                    {widgetLists.length === 0 && <div className="abs-center tw-text-red-500">You have no recent orders</div>}
                    {!(getListsErr || widgetLists.length === 0) && (
                        <ul>
                            {widgetLists.map((list, index) => (
                                <li
                                    className="tw-flex"
                                    key={index}
                                >
                                    <div className="tw-w-3/12 tw-flex tw-justify-center">
                                        {list.defaultImgPath ? (
                                            <img
                                                alt="List Sample Image"
                                                height="40"
                                                width="40"
                                                src={`/ProductImageThumbs67${list.defaultImgPath || '/noimage.png'}`}
                                            />
                                        ) : (
                                            <img
                                                alt="List Sample Image"
                                                height="40"
                                                width="40"
                                                src="/dimg/listicon.png"
                                            />
                                        )}
                                    </div>
                                    <div className="tw-w-9/12">
                                        <a
                                            href={`/lists/${list.listLinkId}`}
                                            className="tw-pt-2"
                                        >
                                            {list.desc}
                                        </a>
                                        <div className="caption">
                                            {list.lastModDate && <>Updated: {TemplateHelpers.formatDate(list.lastModDate)}</>}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </HomePageWidgetStyles>
    );
};
