import styled from '@emotion/styled';

export const ResponsiveImagePromoStyles = styled.div`
    .responsiveBannerWrap {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: calc(140 / 1400 * 100%);
        @media (max-width: 1025px) {
            padding-top: calc(205 / 1045 * 100%);
        }
        @media (max-width: 480px) {
            padding-top: calc(180 / 480 * 100%);
        }

        picture {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
`;
