import * as PrismicDOM from 'prismic-dom';
import * as React from 'react';
import styled from '@emotion/styled';

import {Slice} from '../cms/cms.types';

const ValueAddStyle = styled.div`
    .value-add-content {
        p:last-of-type {
            margin-bottom: 0;
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
        }
    }
`;

export interface ValueAddBannersProps {
    value_add_banners: Slice;
}

export const ValueAddBanners = ({value_add_banners}: ValueAddBannersProps) => {
    if (!value_add_banners) {
        return <></>;
    }
    return (
        <ValueAddStyle>
            <div className={`tw-mb-6 lg:tw-grid tw-grid-cols-${value_add_banners.slice_label === '2_wide' ? '2' : '3'} tw-gap-4`}>
                {value_add_banners.items.map((vaBanner, index) => (
                    <div
                        className="tw-mb-4"
                        key={index}
                    >
                        <div className="card tw-h-full">
                            <div className="card-body">
                                <div className="tw-block md:tw-flex tw-items-center tw-h-full">
                                    <div className="tw-w-full md:tw-w-4/12">
                                        <h2
                                            className={`!tw-font-bold tw-whitespace-nowrap${
                                                value_add_banners.slice_label === '2_wide' ? ' tw-mr-5 h5' : ''
                                            }`}
                                        >
                                            {vaBanner.value_add_title}
                                        </h2>
                                        <div className="tw-border-b-4 tw-pt-2 tw-w-[96px] tw-mb-6"></div>
                                    </div>
                                    <div
                                        className="tw-w-full md:tw-w-8/12 value-add-content"
                                        dangerouslySetInnerHTML={{__html: PrismicDOM.RichText.asHtml(vaBanner.value_add_body_copy)}}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </ValueAddStyle>
    );
};
