import format from 'date-fns/format';

import {FetchService} from '../fetch/fetch.service';
import {
    GetBuyingLocationsRes,
    GetFillRateRes,
    GetShipSaverTotalRes,
    GetSpendAnalysisCategoriesRes,
    GetSpendAnalysisOverTimeRes,
    GetSpendAnalysisTrendRes,
    GetSpendComparisonRes,
    GetSpendOverTimeRes,
    GetSpendTopCategoriesRes,
    GetSpendTopItemsRes,
    GetSpendTopLocationsByDistrictRes,
    GetSpendTopLocationsByDivisionRes,
    GetSpendTopLocationsRes,
    GetTotalInvoicesRes,
    GetTotalUnitsRes,
    SpendAllItemsRow,
    UnitsTopItem,
} from '../../shared/reports/report.types';

export class ReportsService {
    constructor(private _fetchService: FetchService) {}

    /**
     * Gets Buying locations for dashboard headers
     * @param startDate
     * @param endDate
     * @param globalFilter
     */
    public buyingLocations(startDate: Date, endDate: Date, globalFilter: string) {
        return this._fetchService.get<GetBuyingLocationsRes>(
            `/api/reports/buyingLocations?startDate=${format(startDate, 'yyyyMMdd')}&endDate=${format(endDate, 'yyyyMMdd')}${globalFilter}`,
        );
    }

    /**
     * Gets Fill Rate for dashboard headers
     * @param startDate
     * @param endDate
     * @param globalFilter
     */
    public fillRate(startDate: Date, endDate: Date, globalFilter: string) {
        return this._fetchService.get<GetFillRateRes>(
            `/api/reports/fillRate?startDate=${format(startDate, 'yyyyMMdd')}&endDate=${format(endDate, 'yyyyMMdd')}${globalFilter}`,
        );
    }

    /**
     * Gets ship saver total for user
     */
    public getShipSaverTotal() {
        return this._fetchService.get<GetShipSaverTotalRes>(`/api/reports/getShipSaverTotal`);
    }

    /**
     * Returns data for Units: Top Items table
     * @param startDate
     * @param endDate
     */
    public getUnitsTopItems(startDate: Date, endDate: Date) {
        return this._fetchService.get<UnitsTopItem[]>(
            `/api/reports/getUnitsTopItems?startDate=${format(startDate, 'yyyyMMdd')}&endDate=${format(endDate, 'yyyyMMdd')}`,
        );
    }

    /**
     * Retrieves spend data for all items
     * @param startDate
     * @param endDate
     * @param globalFilter
     */
    public spendAllItems(startDate: Date, endDate: Date, globalFilter: string) {
        return this._fetchService.get<SpendAllItemsRow[]>(
            `/api/reports/spendAllItems?startDate=${format(startDate, 'yyyyMMdd')}&endDate=${format(endDate, 'yyyyMMdd')}${globalFilter}`,
        );
    }

    /**
     * Gets Spend analysis over time for Spend analysis
     * @param startDate
     * @param endDate
     * @param globalFilter
     */
    public spendAnalysisOverTime(startDate: Date, endDate: Date, globalFilter: string) {
        return this._fetchService.get<GetSpendAnalysisOverTimeRes>(
            `/api/reports/spendAnalysisOverTime?startDate=${format(startDate, 'yyyyMMdd')}&endDate=${format(
                endDate,
                'yyyyMMdd',
            )}${globalFilter}`,
        );
    }

    /**
     * Gets Spend analysis over time for Spend analysis
     * @param startDate
     * @param endDate
     * @param globalFilter
     */
    public spendAnalysisOverTimeDivision(startDate: Date, endDate: Date, globalFilter: string) {
        return this._fetchService.get<GetSpendAnalysisOverTimeRes>(
            `/api/reports/spendAnalysisOverTimeDivision?startDate=${format(startDate, 'yyyyMMdd')}&endDate=${format(
                endDate,
                'yyyyMMdd',
            )}${globalFilter}`,
        );
    }

    /**
     * Gets Spend over time Trend for Spend Overview
     * @param startDate
     * @param endDate
     * @param globalFilter
     */
    public spendAnalysisTrend(startDate: Date, endDate: Date, globalFilter: string) {
        return this._fetchService.get<GetSpendAnalysisTrendRes>(
            `/api/reports/spendAnalysisTrend?startDate=${format(startDate, 'yyyyMMdd')}&endDate=${format(
                endDate,
                'yyyyMMdd',
            )}${globalFilter}`,
        );
    }

    /**
     * Gets Spend over time Trend for Spend Overview at the District level
     * @param startDate
     * @param endDate
     * @param district
     * @param globalFilter
     * @param showNonBuying
     */
    public spendAnalysisTrendDistrict(startDate: Date, endDate: Date, district: string, globalFilter: string, showNonBuying?: boolean) {
        return this._fetchService.get<GetSpendAnalysisTrendRes>(
            `/api/reports/spendAnalysisTrendDistrict?startDate=${format(startDate, 'yyyyMMdd')}&endDate=${format(
                endDate,
                'yyyyMMdd',
            )}&groupingLevel=${encodeURIComponent(district)}&showNonBuying=${showNonBuying ? 'true' : 'false'}${globalFilter}`,
        );
    }

    /**
     * Gets Spend over time Trend for Spend Overview at the Division level
     * @param startDate
     * @param endDate
     * @param division
     * @param globalFilter
     */
    public spendAnalysisTrendDivision(startDate: Date, endDate: Date, division: string, globalFilter: string) {
        return this._fetchService.get<GetSpendAnalysisTrendRes>(
            `/api/reports/spendAnalysisTrendDivision?startDate=${format(startDate, 'yyyyMMdd')}&endDate=${format(
                endDate,
                'yyyyMMdd',
            )}&groupingLevel=${encodeURIComponent(division)}${globalFilter}`,
        );
    }

    /**
     * Gets Spend comparison for Spend Overview
     * @param startDate
     * @param endDate
     * @param globalFilter
     */
    public spendComparison(startDate: Date, endDate: Date, globalFilter: string) {
        return this._fetchService.get<GetSpendComparisonRes>(
            `/api/reports/spendComparison?startDate=${format(startDate, 'yyyyMMdd')}&endDate=${format(endDate, 'yyyyMMdd')}${globalFilter}`,
        );
    }

    /**
     * Gets Spend over time for Spend Overview
     * @param startDate
     * @param endDate
     * @param globalFilter
     */
    public spendOverTime(startDate: Date, endDate: Date, globalFilter: string) {
        return this._fetchService.get<GetSpendOverTimeRes>(
            `/api/reports/spendOverTime?startDate=${format(startDate, 'yyyyMMdd')}&endDate=${format(endDate, 'yyyyMMdd')}${globalFilter}`,
        );
    }

    /**
     * Gets Top Categories for Spend Overview
     * @param startDate
     * @param endDate
     * @param globalFilter
     */
    public spendTopCategories(startDate: Date, endDate: Date, globalFilter: string) {
        return this._fetchService.get<GetSpendTopCategoriesRes>(
            `/api/reports/spendTopCategories?startDate=${format(startDate, 'yyyyMMdd')}&endDate=${format(
                endDate,
                'yyyyMMdd',
            )}${globalFilter}`,
        );
    }

    /**
     * Gets Top Items for Spend Overview
     * @param startDate
     * @param endDate
     * @param globalFilter
     */
    public spendTopItems(startDate: Date, endDate: Date, globalFilter: string) {
        return this._fetchService.get<GetSpendTopItemsRes>(
            `/api/reports/spendTopItems?startDate=${format(startDate, 'yyyyMMdd')}&endDate=${format(endDate, 'yyyyMMdd')}${globalFilter}`,
        );
    }

    /**
     * Gets Top Locations for Spend Overview
     * @param startDate
     * @param endDate
     * @param globalFilter
     */
    public spendTopLocations(startDate: Date, endDate: Date, globalFilter: string) {
        return this._fetchService.get<GetSpendTopLocationsRes>(
            `/api/reports/spendTopLocations?startDate=${format(startDate, 'yyyyMMdd')}&endDate=${format(
                endDate,
                'yyyyMMdd',
            )}${globalFilter}`,
        );
    }

    /**
     * Gets Top Locations By District for Spend Overview
     * @param startDate
     * @param endDate
     * @param globalFilter
     */
    public spendTopLocationsByDistrict(startDate: Date, endDate: Date, globalFilter: string) {
        return this._fetchService.get<GetSpendTopLocationsByDistrictRes>(
            `/api/reports/spendTopLocationsByDistrict?startDate=${format(startDate, 'yyyyMMdd')}&endDate=${format(
                endDate,
                'yyyyMMdd',
            )}${globalFilter}`,
        );
    }

    /**
     * Gets Top Locations By Division for Spend Overview
     * @param startDate
     * @param endDate
     * @param globalFilter
     */
    public spendTopLocationsByDivision(startDate: Date, endDate: Date, globalFilter: string) {
        return this._fetchService.get<GetSpendTopLocationsByDivisionRes>(
            `/api/reports/spendTopLocationsByDivision?startDate=${format(startDate, 'yyyyMMdd')}&endDate=${format(
                endDate,
                'yyyyMMdd',
            )}${globalFilter}`,
        );
    }

    /**
     * Gets Total Invoices for dashboard headers
     * @param startDate
     * @param endDate
     * @param globalFilter
     */
    public totalInvoices(startDate: Date, endDate: Date, globalFilter: string) {
        return this._fetchService.get<GetTotalInvoicesRes>(
            `/api/reports/totalInvoices?startDate=${format(startDate, 'yyyyMMdd')}&endDate=${format(endDate, 'yyyyMMdd')}${globalFilter}`,
        );
    }

    /**
     * Gets Total spend for dashboard headers
     * @param startDate
     * @param endDate
     * @param globalFilter
     */
    public totalSpend(startDate: Date, endDate: Date, globalFilter: string) {
        return this._fetchService.get<GetSpendTopItemsRes>(
            `/api/reports/totalSpend?startDate=${format(startDate, 'yyyyMMdd')}&endDate=${format(endDate, 'yyyyMMdd')}${globalFilter}`,
        );
    }

    /**
     * Gets Total Units for dashboard headers
     * @param startDate
     * @param endDate
     * @param globalFilter
     */
    public totalUnits(startDate: Date, endDate: Date, globalFilter: string) {
        return this._fetchService.get<GetTotalUnitsRes>(
            `/api/reports/totalUnits?startDate=${format(startDate, 'yyyyMMdd')}&endDate=${format(endDate, 'yyyyMMdd')}${globalFilter}`,
        );
    }

    /**
     * Gets Spend Analysis for categories
     * @param startDate
     * @param endDate
     * @param globalFilter
     */
    public spendAnalysisCategories(startDate: Date, endDate: Date, globalFilter: string) {
        return this._fetchService.get<GetSpendAnalysisCategoriesRes>(
            `/api/reports/spendAnalysisCategories?startDate=${format(startDate, 'yyyyMMdd')}&endDate=${format(
                endDate,
                'yyyyMMdd',
            )}${globalFilter}`,
        );
    }
}
