import * as React from 'react';

import {WidgetCMSLists} from '../lists/lists/lists.class';
import {CollapseZone} from './CollapseZone';

export interface GridListWidgetProps {
    widgetCMSLists: WidgetCMSLists;
}

export const GridListWidget = ({widgetCMSLists}: GridListWidgetProps) => {
    if (!(widgetCMSLists && widgetCMSLists.layout_type === `Grid View` && widgetCMSLists.lists?.length > 0)) {
        return <></>;
    }
    return (
        <CollapseZone title={widgetCMSLists.custom_widget_title || 'My Preferred Products'}>
            <div className={`tw-grid tw-grid-cols-2 md:tw-grid-cols-4 lg:tw-grid-cols-6 tw-gap-4`}>
                {widgetCMSLists.lists.map((cmsList, index) => (
                    <div
                        key={index}
                        className="tw-border tw-h-full tw-p-3"
                    >
                        {cmsList.custom_pill && <div className="badge badge-pill badge-info border-0">{cmsList.custom_pill}</div>}
                        <a
                            href={cmsList.full_url || `/lists/${cmsList.list_number}`}
                            className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-center"
                        >
                            <img
                                alt={cmsList.list_name}
                                src={`/ProductImageThumbs240${cmsList.image_url || '/noimage.png'}`}
                                className="tw-max-w-full tw-h-auto tw-my-3"
                                width="120"
                                height="120"
                            />
                            <div>{cmsList.list_name}</div>
                        </a>
                    </div>
                ))}
            </div>
        </CollapseZone>
    );
};
