import {User} from '../users/user.class';

export type OrderHeaderLabel = `Backorder` | `Order` | `Quote`;
export type OrderHeaderType = `backorder` | `order` | `quote`;
type OrderHeaderStatus = `Backorder` | `Expired` | `In Process` | `Open` | `Order Declined` | `Pending Approval`;

export class OrderHeader {
    account: string;
    amount: number;
    aprxFrtTot: number;
    aprxTax: number;
    aribaDt?: string;
    attn?: string;
    canceledFilter: boolean;
    city: string;
    confirmNbr?: string;
    currency?: string;
    currRate?: string;
    details: string;
    editDtTm?: string;
    invcDt?: string;
    invcNbr?: string;
    location: string;
    login: string;
    managerComment: string;
    managerStatus: boolean;
    orderLink?: string;
    orderReadOnly: boolean;
    ordLocation: string;
    ordrDt: string;
    ordrLines: number;
    ordrNbr: string;
    po: string;
    recStatus: number;
    st: string;
    status: OrderHeaderStatus;
    statusDt: string;
    trackLink?: string;
    trkAvail: boolean;
    type: `` | `Q`;
    vocNbr?: string;
    zip: string;

    /**
     * Returns true if an order can be deleted
     * @param orderHeader
     * @param user
     */
    public static canDelete(orderHeader: OrderHeader, user: User): boolean {
        // Read only orders cannot be deleted
        if (orderHeader.orderReadOnly) {
            return false;
        }

        // Punchout users cannot delete back orders
        if (user.isPunchout && orderHeader.status === `Backorder`) {
            return false;
        }

        // Quotes can only be deleted by the creator
        return !(orderHeader.type === `Q` && orderHeader.login !== user.loginName);
    }

    /**
     * Returns latest date on provided orderHeader
     * @param orderHeader
     */
    public static latestDate(orderHeader: OrderHeader): string {
        return orderHeader.invcDt || orderHeader.editDtTm || orderHeader.ordrDt;
    }

    /**
     * Sorts order headers with most relevant first
     * @param orderHeaders
     */
    public static sortOrderHeaders(orderHeaders: OrderHeader[]): OrderHeader[] {
        orderHeaders.sort((orderHeader1, orderHeader2) => {
            // Move open orders to the top
            if (orderHeader1.status === `Open` && orderHeader2.status !== `Open`) {
                return -1;
            }
            if (orderHeader1.status !== `Open` && orderHeader2.status === `Open`) {
                return 1;
            }

            // Move pending approval orders second
            if (orderHeader1.status === `Pending Approval` && orderHeader2.status !== `Pending Approval`) {
                return -1;
            }
            if (orderHeader1.status !== `Pending Approval` && orderHeader2.status === `Pending Approval`) {
                return 1;
            }

            // latestDate desc (newer first)
            if (OrderHeader.latestDate(orderHeader1) > OrderHeader.latestDate(orderHeader2)) {
                return -1;
            }
            if (OrderHeader.latestDate(orderHeader1) < OrderHeader.latestDate(orderHeader2)) {
                return 1;
            }

            return 0;
        });
        return orderHeaders;
    }
}
